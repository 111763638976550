<template>
	<div class="grid">
		<div class="col-12">
			<Card>
                <template #title>
                    {{title}}
                </template>
                <template #content>
					<div class="p-fluid">
                        <div class="field grid">
                            <label class="col-12 mb-2 md:col-4 md:mb-0">Site Name</label>
                            <div class="col-12 md:col-8">
                                <InputText v-model="dataForm.site_name"
                                    :class="{ 'p-invalid': dataForm.errors.has('site_name') }" />
                                <small class="p-error" v-show="dataForm.errors.has('site_name')">
                                    {{ dataForm.errors.get('site_name') }}
                                </small>
                            </div>
                        </div>
                        <div class="field grid">
                            <label class="col-12 mb-2 md:col-4 md:mb-0">Favicon (50x50)</label>
                            <div class="col-12 md:col-8">
                                <Image :src="favicon" preview alt="" />
                                <InputText @change="selectFavicon" type="file" accept="image/*"
                                    :class="{ 'p-invalid': dataForm.errors.has('favicon') }" />
                                <small class="p-error" v-show="dataForm.errors.has('favicon')">
                                    {{ dataForm.errors.get('favicon') }}
                                </small>
                            </div>
                        </div>
                        <div class="field grid">
                            <label class="col-12 mb-2 md:col-4 md:mb-0">Logo (500x200)</label>
                            <div class="col-12 md:col-8">
                                <Image :src="logo" preview alt="" />
                                <InputText @change="selectLogo" type="file" accept="image/*"
                                    :class="{ 'p-invalid': dataForm.errors.has('logo') }" />
                                <small class="p-error" v-show="dataForm.errors.has('logo')">
                                    {{ dataForm.errors.get('logo') }}
                                </small>
                            </div>
                        </div>
                        <div class="field grid">
                            <label class="col-12 mb-2 md:col-4 md:mb-0">Map Location</label>
                            <div class="col-12 md:col-8">
                                <Textarea v-model="dataForm.map_location"
                                    :class="{ 'p-invalid': dataForm.errors.has('map_location') }" />
                                <small class="p-error" v-show="dataForm.errors.has('map_location')">
                                    {{ dataForm.errors.get('map_location') }}
                                </small>
                            </div>
                        </div>
                        <div class="field grid">
                            <label class="col-12 mb-2 md:col-4 md:mb-0">Meta Description</label>
                            <div class="col-12 md:col-8">
                                <Textarea v-model="dataForm.meta_description"
                                    :class="{ 'p-invalid': dataForm.errors.has('meta_description') }" />
                                <small class="p-error" v-show="dataForm.errors.has('meta_description')">
                                    {{ dataForm.errors.get('meta_description') }}
                                </small>
                            </div>
                        </div>
                        <div class="field grid">
                            <label class="col-12 mb-2 md:col-4 md:mb-0">Meta Keyword</label>
                            <div class="col-12 md:col-8">
                                <Textarea v-model="dataForm.meta_keyword"
                                    :class="{ 'p-invalid': dataForm.errors.has('meta_keyword') }" />
                                <small class="p-error" v-show="dataForm.errors.has('meta_keyword')">
                                    {{ dataForm.errors.get('meta_keyword') }}
                                </small>
                            </div>
                        </div>
                        <div class="field grid">
                            <label class="col-12 mb-2 md:col-4 md:mb-0">Footer</label>
                            <div class="col-12 md:col-8">
                                <Editor
                                    v-model="dataForm.footer"
                                    :class="{ 'p-invalid': dataForm.errors.has('footer') }"
                                    :api-key="$tinymce_api_key"
                                    :init="{
                                        height: 130,
                                        plugins: 'print preview paste importcss searchreplace autolink directionality code visualblocks visualchars fullscreen image link media codesample table charmap hr nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap emoticons',
                                        menubar: false,
                                        toolbar: 'undo redo | bold italic underline strikethrough | charmap emoticons | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | charmap emoticons | fullscreen  preview print | image media link anchor codesample | ltr rtl',
                                        valid_children: '+body[style]'
                                    }"
                                />
                                <small class="p-error" v-show="dataForm.errors.has('footer')">
                                    {{ dataForm.errors.get('footer') }}
                                </small>
                            </div>
                        </div>
                        <div class="field grid">
                            <label class="col-12 mb-2 md:col-4 md:mb-0">Maintenance</label>
                            <div class="col-12 md:col-8">
                                <InputSwitch v-model="dataForm.maintenance"
                                    :class="{ 'p-invalid': dataForm.errors.has('maintenance') }" />
                                <small class="p-error" v-show="dataForm.errors.has('maintenance')">
                                    {{ dataForm.errors.get('maintenance') }}
                                </small>
                            </div>
                        </div>
                    </div>
                    <Button label="Save" @click="submitData" :disabled="dataForm.busy" :icon="(dataForm.busy) ? 'pi pi-spin pi-spinner' : 'pi pi-save'" />
				</template>
			</Card>

		</div>
	</div>

</template>

<script>
export default {
    props: ['settings'],
	data() {
		return {
			title: 'General Setting',
			api: '/api/settings',
			dataForm: new this.$FormAuth({
				site_name: null,
                favicon: null,
                logo: null,
                map_location: null,
                meta_description: null,
                meta_keyword: null,
                footer: null,
                maintenance: null,
			}),
			relations: {
                permissions: null,
            },
		}
	},
    computed: {
        favicon(){
			return (this.settings.general.favicon) ? process.env.VUE_APP_ASSETS_FOLDER + '/' + this.settings.general.favicon : process.env.VUE_APP_DEFAULT_FAVICON;
		},
		logo(){
			return (this.settings.general.logo) ? process.env.VUE_APP_ASSETS_FOLDER + '/' + this.settings.general.logo : process.env.VUE_APP_DEFAULT_LOGO;
		},
	},
	methods: {
		loadData(){
			this.$Progress.start();
			this.$httpAuth.get(this.api + '/general')
			.then((response) => {
				this.$Progress.finish();
                response.data.favicon = null;
                response.data.logo = null;
				this.dataForm.fill(response.data);
			})
			.catch((error) => {
				this.$Progress.fail();
				this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
			});
		},

        selectFavicon(e){
			const file = e.target.files[0];
			this.dataForm.favicon = file;
		},
        selectLogo(e){
			const file = e.target.files[0];
			this.dataForm.logo = file;
		},

        submitData(){
			this.$Progress.start();
			this.dataForm.post(this.api + '/general')
            .then((response) => {
                this.$Progress.finish();
                this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
                this.loadData();
                this.$e.emit('getSettings', true);
            })
            .catch((error) => {
                this.$Progress.fail();
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		},
	},
	created(){
		this.$e.emit('updateTitle', this.title);
	},
	mounted() {
		this.loadData();
	},
}
</script>

<style lang="scss" scoped>
.field-checkbox {
	margin-bottom: unset;
}
</style>